<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="徽章分类" prop="badgeTypeId" >
        <a-select v-model="form.badgeTypeId" style="width: 100%" placeholder="请选择分类" :options="ConfigBadgeTypeOptions"
                  @change="selectFilterConfigBadgeTypeChange"></a-select>
      </a-form-model-item>
      <a-form-model-item label="徽章分组" prop="sort"v-if="isShow">
        <a-select v-model="form.sort" style="width: 100%" placeholder="请选择分组" :options="ConfigBadgeTypeOptionsGroup"
                  @change="selectFilterConfigBadgeTypeChangeGroup"></a-select>
      </a-form-model-item>
      <a-form-model-item label="徽章名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入徽章名称"/>
      </a-form-model-item>
      <a-form-model-item label="徽章级别" prop="level" v-if="isShow">
        <!--        <a-input v-model="form.level" placeholder="请输入徽章级别" />-->
        <a-input-number v-model:value="form.level" :min="0" :max="9999" :precision="0" placeholder="请输入徽章级别"/>
      </a-form-model-item>
      <a-form-model-item label="升级所需的组局题材" prop="badgeReachFilter" v-if="isShow2">
        <api-select v-model="form.badgeReachFilter" placeholder="升级所需的组局题材"
                    api="/script/script/filter/select-option?type=2" multi
                    :searchKeys="['name']">
          <template v-slot="{ option }">
            <span>{{ option.name }}</span>
          </template>
        </api-select>
      </a-form-model-item>
      <a-form-model-item label="升1级所需的组局数量" prop="groupNum" v-if="isShow1">
<!--        <a-input v-model="form.groupNum" placeholder="请输入升1级所需的组局数量"/>-->
        <a-input-number v-model:value="form.groupNum" :min="0" :max="9999" :precision="0" placeholder="请输入组局数量"/>
      </a-form-model-item>
      <a-form-model-item label="未点亮图片" prop="notLightImg">
        <a-upload name="notLightImg" v-model="fileList1" listType="picture-card" :fileList="fileList1"
                  :remove="removeFile1" class="avatar-uploader" :multiple="true" :show-upload-list="true"
                  :preview="handlePreview"
                  :before-upload="beforeOssUpload" :customRequest="coverAvatarUploadInfo1">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="已点亮图片" prop="lightImg">
        <a-upload name="lightImg" v-model="fileList" listType="picture-card" :fileList="fileList" :remove="removeFile"
                  class="avatar-uploader" :multiple="true" :show-upload-list="true" :preview="handlePreview"
                  :before-upload="beforeOssUpload" :customRequest="coverAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <!--      <a-form-model-item label="点亮人数" prop="lightNum" >-->
      <!--        <a-input v-model="form.lightNum" placeholder="请输入点亮人数" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="虚拟点亮人数" prop="lightNumVirtual">
        <a-input v-model="form.lightNumVirtual" placeholder="请输入虚拟点亮人数"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="备注" prop="remark" >-->
      <!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="是否绝版" prop="isOut">
        <a-switch v-model="form.isOut" />{{ form.isOut ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="达成条件描述" prop="reach">
        <a-input v-model="form.reach" placeholder="请输入达成条件"/>
      </a-form-model-item>
      <a-form-model-item label="是否顶级" prop="top">
        <a-switch v-model="form.top" />{{ form.top ? '是' : '否' }}
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getBadge, addBadge, updateBadge} from '@/api/config/badge'
import {loadFilterOptions, loadFilterOptionsGroup} from "@/api/config/badge";
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import ApiSelect from './ApiSelect.vue';

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ApiSelect
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      fileList: [],
      fileList1: [],
      isShow: true,
      isShow1: true,
      isShow2: true,
      ConfigBadgeTypeOptions: [],
      ConfigBadgeTypeOptionsGroup: [],
      // 表单参数
      form: {
        id: null,

        badgeTypeId: null,

        isOut: null,
        sort: null,

        name: null,
        alone: null,
        top: null,
        level: null,

        notLightImg: null,

        lightImg: null,

        lightNum: null,

        lightNumVirtual: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,
        reach: null,
        groupNum: null,
        badgeReachFilter: [],
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        badgeTypeId: [
          {required: true, message: '徽章分类id不能为空', trigger: 'blur'}
        ],

        name: [
          {required: true, message: '徽章名称不能为空', trigger: 'blur'}
        ],

        sort: [
          {required: true, message: '徽章分类不能为空', trigger: 'blur'}
        ],

        level: [
          {required: true, message: '徽章级别不能为空', trigger: 'blur'}
        ],
        notLightImg: [
          {required: true, message: '未点亮图片不能为空', trigger: 'blur'}
        ],

        lightImg: [
          {required: true, message: '已点亮图片不能为空', trigger: 'blur'}
        ],
        isOut: [
          {required: true, message: '是否绝版不能为空', trigger: 'blur'}
        ],
        top: [
          {required: true, message: '是否顶级不能为空', trigger: 'blur'}
        ],
        /*notLightImg: [
          { required: true, message: '未点亮图片不能为空', trigger: 'blur' }
        ],

        lightImg: [
          { required: true, message: '已点亮图片不能为空', trigger: 'blur' }
        ],

        lightNum: [
          { required: true, message: '点亮人数不能为空', trigger: 'blur' }
        ],

        lightNumVirtual: [
          { required: true, message: '虚拟点亮人数不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]*/

      }
    }
  },
  filters: {},
  created() {
    this.initConfigBadgeTypeOptions()
    this.initConfigBadgeTypeOptionsGroup()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.fileList = []
      this.fileList1 = []
      this.isShow = true
      this.isShow1 = true
      this.isShow2 = true
      this.form = {
        id: null,

        badgeTypeId: null,
        isOut: null,
        sort: null,
        name: null,
        alone: null,
        top: null,
        level: null,

        notLightImg: null,

        lightImg: null,

        lightNum: null,

        lightNumVirtual: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,
        reach: null,
        groupNum: null,
        badgeReachFilter: [],
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.isShow = null
      this.isShow1 = null
      this.isShow2 = null
      this.open = true
      this.formTitle = '添加'
    },
    changeType(value) {
      console.log("123" + value);
      this.isShow = false;
      if (value == 1) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = true;
      }
      if (value == 2) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = false;
      }
      if (value == 3) {
        this.isShow = false;
        this.isShow1 = false;
        this.isShow2 = false;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBadge(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        // 处理图片反显
        if (response.data.lightImg !== undefined && response.data.lightImg !== null && JSON.stringify(response.data.lightImg) !== '"{}"') {
          var images = this.form.lightImg.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'lightImg.jpg'})
          }
        }
        if (response.data.notLightImg !== undefined && response.data.notLightImg !== null && JSON.stringify(response.data.notLightImg) !== '"{}"') {
          var images = this.form.notLightImg.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList1.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'notLightImg.jpg'})
          }
        }
      })
    },
    initConfigBadgeTypeOptions() {
      loadFilterOptions({type: 4}).then(res => {
        this.ConfigBadgeTypeOptions = res.data
      })
    },
    initConfigBadgeTypeOptionsGroup() {
      loadFilterOptionsGroup({type: 4}).then(res => {
        this.ConfigBadgeTypeOptionsGroup = res.data
      })
    },
    selectFilterConfigBadgeTypeChangeGroup(value) {
      this.form.sort = value
    },
    selectFilterConfigBadgeTypeChange(value) {
      this.form.badgeTypeId = value

      console.log("123" + value);
      this.isShow = false;
      if (value == 325667176575033344) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = true;
      }
      if (value == 274544925851500544) {
        this.isShow = true;
        this.isShow1 = true;
        this.isShow2 = false;
      }
      if (value == 299234188392005632) {
        this.isShow = false;
        this.isShow1 = false;
        this.isShow2 = false;
      }
    },// oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'activityPics'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'activityPics', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      this.fileList = fileList
      var info = ''
      fileList.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'lightImg', info)
      console.log('info=', _this.form.info)
    },
    // 删除图片
    removeFile1(file) {
      var fileList1 = []
      this.fileList1.forEach(e => {
        if (e.url !== file.url) {
          fileList1.push(e)
        }
      })
      this.fileList1 = fileList1
      var info = ''
      fileList1.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'notLightImg', info)
      console.log('info=', _this.form.info)
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'prize'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'lightImg.jpg'})
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'lightImg', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo1(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'prize'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList1.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'notLightImg.jpg'})
          var info = ''
          _this.fileList1.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'notLightImg', info)
          console.log('info=', _this.form.info)
          _this.$message.success('上传成功')
        })
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBadge(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBadge(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
